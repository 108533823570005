@import 'mixins.scss';

:root, * {
  --min-dimension: anyInvalidValue;
  --max-dimension: anyInvalidValue;
}

/** LAYOUTS **/

@include makeItResponsive('flex-row') {
  display: flex !important;
  flex-direction: row !important;
  box-sizing: border-box !important;

  & > * {
    max-width: var(--max-dimension, unset);
    min-width: var(--min-dimension, unset);
  }
}

@include makeItResponsive('flex-column') {
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;

  & > * {
    max-height: var(--max-dimension, unset);
    min-height: var(--min-dimension, unset);
  }
}

@include makeItResponsive('flex-row-reverse') {
  display: flex !important;
  flex-direction: row-reverse !important;
  box-sizing: border-box !important;

  & > * {
    max-width: var(--max-dimension, unset);
    min-width: var(--min-dimension, unset);
  }
}

@include makeItResponsive('flex-column-reverse') {
  display: flex !important;
  flex-direction: column-reverse !important;
  box-sizing: border-box !important;

  & > * {
    max-height: var(--max-dimension, unset);
    min-height: var(--min-dimension, unset);
  }
}

/** WRAP **/

@include makeItResponsive('wrap') {
  flex-wrap: wrap !important;
}

@include makeItResponsive('no-wrap') {
  flex-wrap: nowrap !important;
}

@include makeItResponsive('wrap-reverse') {
  flex-wrap: wrap-reverse !important;
}

/** ALIGNMENT **/

@include makeItResponsive('justify-start') {
  justify-content: flex-start !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('justify-end') {
  justify-content: flex-end !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('justify-center') {
  justify-content: center !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('justify-between') {
  justify-content: space-between !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('justify-around') {
  justify-content: space-around !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('align-start') {
  align-items: flex-start !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('align-end') {
  align-items: flex-end !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('align-center') {
  align-items: center !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('align-stretch') {
  align-items: stretch !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('align-baseline') {
  align-items: baseline !important;
  display: flex;
  box-sizing: border-box !important;
}

/** CONTENT ALIGNMENT **/

@include makeItResponsive('content-center') {
  align-content: center !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('content-start') {
  align-content: flex-start !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('content-end') {
  align-content: flex-end !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('content-between') {
  align-content: space-between !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('content-around') {
  align-content: space-around !important;
  display: flex;
  box-sizing: border-box !important;
}

@include makeItResponsive('content-stretch') {
  align-content: stretch !important;
  display: flex;
  box-sizing: border-box !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-auto-min {
  flex: 1 0 auto !important;
}

.flex-auto-max {
  flex: 0 1 auto !important;
}

.flex-auto-minmax {
  flex: 0 0 auto !important;
}


/*** FLEX MIXIN ***/
@for $i from 0 through 100 {
  @include makeItResponsive('flex-#{$i}') {
    @include flex-max(#{$i + '%'});
  }

  @include makeItResponsive('flex-#{$i}-max') {
    @include flex-max(#{$i + '%'});
  }

  @include makeItResponsive('flex-#{$i}-min') {
    @include flex-min(#{$i + '%'});
  }

  @include makeItResponsive('flex-#{$i}-minmax') {
    @include flex-minmax(#{$i + '%'});
  }
}

@include makeItResponsive('flex') {
  @include flex(1e-9px);
}


/*** Grow and shrink ***/

@for $i from 0 through 10 {
  @include makeItResponsive(grow-#{$i}) {
    flex-grow: #{$i} !important;
    box-sizing: border-box !important;
  }
}
@for $i from 0 through 10 {
  @include makeItResponsive(shrink-#{$i}) {
    flex-shrink: #{$i} !important;
    box-sizing: border-box !important;
  }
}

/** Self Alignment **/

@include makeItResponsive('self-center') {
  align-self: center !important;
}

@include makeItResponsive('self-center-center') {
  align-items: center !important;
  align-content: center !important;
}

@include makeItResponsive('self-start') {
  align-self: start !important;
}

@include makeItResponsive('self-stretch-center') {
  align-items: stretch !important;
  align-content: center !important;
}

/** Hide and Show **/

@include makeItResponsive('hide') {
  display: none !important;
}

@include makeItNegativeResponsive('show') {
  display: none !important;
}

/*** Layout Gap ***/

@for $i from 0 through 5 {
  .gap-#{$i} {
    @include responsive(gt-sm) {
      gap: calc($i * 8px);
    }
  }

  .gap-#{$i}\.5 {
    @include responsive(gt-sm) {
      gap: calc(($i * 8px) + 4px);
    }
  }
}
