@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@import 'variables.scss';
@import 'flex-layout.scss';
@import 'css-framework.scss';

$textarea-typography: mat.define-legacy-typography-config(
  $font-family: $primary-font-family,
  $input: mat.define-typography-level(14px, 1.5, 400),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($textarea-typography);`
@include mat.all-legacy-component-typographies($textarea-typography);
@include mat.legacy-core();

@include mat.all-legacy-component-themes($pl-app-theme);

@page {
  size: A4;
  margin: 15mm 0mm;
}

html > body > app-root:has(simple-lab-report) {
  overflow: inherit;
  .section-container {
    margin-bottom: 30px;
    question-section,
    trial-section,
    speech-to-text-section {
      button {
        display: none;
      }
    }
    &:has(phet-section),
    &:has(shared-data-table-bar-graph),
    &:has(shared-drawing-section),
    &:has(shared-data-table-map),
    &:has(live-trial-section),
    &:has(trial-section) {
      break-inside: avoid;
    }
    entry-form-rich-text > .container {
      flex-direction: unset !important;
      height: unset;
      & > quill-editor {
        height: unset;
      }
    }
  }
  mat-cell,
  mat-header-cell {
    border: 0.5px solid black !important; // Ensures borders are visible when printed
  }

  line-chart {
    div {
      canvas {
        width: 100% !important;
      }
    }
  }
}

html {
  body {
    margin: 0px;
    @extend .mat-typography;
    font-display: swap;
    overscroll-behavior: contain;

    cloudlab-sign-in,
    cloudlab-student-sign-in,
    cloudlab-register,
    cloudlab-role-confirm,
    cloudlab-forgot-password,
    cloudlab-new-password,
    cloudlab-resend-code,
    cloudlab-register-temp-user,
    cloudlab-verification {
      overflow-y: auto;
      padding: 8px;
      margin-bottom: 8px;
      display: block;
      overflow: scroll;
      height: 100dvh;
      box-sizing: border-box;
    }

    app-cloudlab,
    app-root,
    app-root > optional-data-view,
    app-root > nar {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
    }

    .mat-pseudo-checkbox {
      height: 16px;
      width: 16px;

      &::after {
        color: white !important;
      }
    }

    .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
      height: 3px;
      width: 8px;
    }

    p.instructions {
      font-weight: 500;
      margin: 20px 0 10px;
      &.first {
        margin-top: 0px;
      }
    }

    h1 {
      font-size: 34px;
    }

    .section-header {
      margin: 20px 0 10px;
      font-size: 16px;
      font-weight: 400;
      &.first {
        margin-top: 5px;
      }
    }

    .bottom-btns {
      margin-top: 20px;
    }

    .secondary-text {
      color: $secondary-text-color;
    }

    [hidden] {
      display: none !important;
    }

    a {
      cursor: pointer !important;
      &.tutorial-link {
        cursor: crosshair;
        color: white;
      }
    }

    span {
      &.tutorial-step {
        opacity: 0.8;
        color: white;
      }
    }

    mat-selection-list, .mat-dialog-content {
      user-select: none;
    }

    .dygraph-label,
    .dygraph-axis-label {
      font-size: 14px;
      line-height: 14px;
    }

    router-outlet.grow + * {
      display: flex;
      flex-direction: column !important;
      min-height: 100%;
      overflow: hidden;
    }

    html body h3.infowindow-name {
      margin-bottom: 0px;
    }

    entry-form,
    marker-dialog,
    entry-form-input,
    entry-form-rich-text,
    tag-editor {
      mat-form-field {
        .mat-form-field-infix {
          border-top-width: 0;
        }
        &:not(.editing):not(:hover),
        &.readonly {
          white-space: pre-wrap;
          .mat-form-field-underline {
            display: none;
          }
        }
      }
    }

    div {
      mat-list.mat-list {
        mat-list-item.mat-list-item {
          .mat-list-text {
            p.mat-line {
              font-size: 14px;
            }
          }
          &.no-padding {
            .mat-list-item-content {
              padding: 0px !important;
            }
          }
        }
      }
    }

    .mat-button,
    .mat-raised-button {
      border-radius: $standard-radius;
    }

    .primary-action,
    .secondary-action,
    .tertiary-action,
    .state-change-action {
      .mat-form-field-outline-start {
        border-radius: 8px 0 0 8px !important;
      }
      .mat-form-field-outline-end {
        border-radius: 0 8px 8px 0 !important;
      }
      .mat-form-field-infix {
        padding: 0.66em 0 !important;
        border-top-width: 0;
        .mat-select-arrow {
          margin-top: 16px;
        }
        .mat-select-value {
          padding-top: 4px;
        }
      }
      &.mat-button,
      &.mat-form-field-outline {
        border-width: 1px;
        border-style: solid;
        &.mat-button-disabled {
          border-color: unset;
        }
      }
    }

    .primary-action {
      &.mat-button,
      &.mat-form-field-outline {
        background-color: $background-orange-button;
        color: $border-orange-button;
        border-color: $border-orange-button;
      }
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-color: $border-orange-button;
      }
    }

    .secondary-action {
      &.mat-button,
      .mat-form-field-outline {
        background-color: $background-blue-button;
        color: $border-blue-button;
      }
      mat-select-trigger,
      .mat-select-placeholder,
      .mat-select-arrow {
        color: $border-blue-button;
        font-size: 14px;
        font-weight: 500;
      }
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-color: $border-blue-button;
      }
    }

    .tertiary-action {
      &.mat-button,
      .mat-form-field-outline {
        background-color: $background-green-button;
        color: $border-green-button;
      }
      mat-select-trigger,
      .mat-select-placeholder,
      .mat-select-arrow {
        color: $border-green-button;
        font-size: 14px;
        font-weight: 500;
      }
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-color: $border-green-button;
      }
    }

    .mat-form-field-outline {
      border-radius: $standard-radius;
    }

    .mat-paginator {
      background: transparent;
    }

    @media screen and (max-width: 375px) {
      span.units {
        font-size: 11px;
      }
    }

    @media screen and (max-width: 375px) {
      div.l {
        font-size: 11px;
      }
    }

    div.content-owner {
      font-style: oblique;
      color: gray;
      margin-bottom: 20px;
      div {
        position: absolute;
      }
    }

    .dialog-header {
      h3 {
        font-size: 20px;
        margin-bottom: 0px;
        line-height: 40px;
        font-weight: normal;
        text-overflow: ellipse;
        overflow: hidden;
      }
      .mat-icon-button {
        top: -24px;
        right: -24px;
        &.cdk-focused {
          .mat-button-focus-overlay {
            opacity: 0;
          }
        }
        mat-icon {
          color: $secondary-text-color;
        }
      }
    }

    mat-dialog-content {
      .content {
        margin-top: 10px;
        width: 100%;
        // padding: 0 7%;
        input {
          font-size: 15px;
        }
        p,
        li {
          font-size: 15px;
          color: $secondary-text-color;
          a.mat-button {
            font-size: 15px;
            color: black;
          }
          a:not(.mat-button) {
            text-decoration: underline;
            color: $secondary-text-color !important;
            &:hover {
              color: black !important;
            }
          }
        }
        button {
          line-height: 45px;
          font-size: 14px;
          // margin-top: 10px;
          text-transform: none;
        }
      }
    }

    mat-dialog-actions {
      align-self: flex-end;
      float: right;
    }

    mat-dialog-actions,
    .action-btns {
      button {
        height: 45px;
        font-size: 14px;
        text-transform: none;
      }
    }
    .mat-menu-panel {
      border-radius: $standard-radius;
      mat-button-toggle-group {
        border-radius: 15px;
        margin-top: 10px;
      }
    }

    .mat-menu-item {
      height: auto;
      padding-bottom: 10px;
      padding-top: 10px;
      line-height: 16px;
      margin-top: 0px;
    }
    .mat-button-toggle-label-content {
      line-height: 25px;
      // color: $accent-color-standard;
    }

    mat-button-toggle {
      width: 100%;
    }

    data-analysis-bar-graph {
      text:not([text-anchor='end']), path {
        cursor: pointer;
      }
      text[text-anchor='middle'], rect {
        cursor: pointer;
      }
      g[clip-path] {
        g:nth-child(2) {
          rect {
            cursor: pointer;
          }
        }
      }
    }

    data-analysis-scatter-plot {
      .dygraph-label {
        cursor: pointer;
      }
      .dygraph-ylabel {
        margin-top: 12px;
      }
    }

    .mat-dialog-container {
      border-radius: $standard-radius;
    }

    .wide-border-dialog .mat-dialog-container {
      padding: 52px;
      border-radius: $standard-radius;
    }
  }

  .div-mat-toggle {
    border-radius: $standard-radius;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    border: 0.5px solid $border-standard-light;
  }

  // Quill editor themes

  // Setting to inherit allows us to style quill according to lesson theme information without it being
  // overridden by the defaults
  .ql-container {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  quill-editor {
    // this is the default font-size if a theme isn't set and nothing is set in the editor
    font-size: 16px;
    .ql-container {
      .ql-editor {
        &.ql-blank::before {
          margin-left: 0;
        }
        line-height: 1.6;
        padding: 16px 12px;
        img {
          cursor: pointer;
        }
      }
    }
  }
  .ql-editor.ql-blank::before {
    margin-left: -16px;
  }
  .ql-snow .ql-tooltip.ql-flip {
    z-index: 99;
  }
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: black;
  }
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: black;
  }
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: black;
  }

  .ql-snow .ql-editor h4 {
    font-size: 1.15em;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    bottom: 27px;
    right: 0px;
    top: auto;
    z-index: 99;
  }

  .ql-toolbar {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .toolbar-container {
    padding: 15px;
    background-color: white;
    background: #FFFFFF;
    border: 1px solid $border-standard-light;
    box-sizing: border-box;
    box-shadow:
      0px 11px 15px -7px rgba(0, 0, 0, 0.1),
      0px 24px 38px 3px rgba(0, 0, 0, 0.04),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    div.dismiss {
      margin-left: auto;
      width: 30px;
      height: 30px;
      right: 12px;
      top: 25.5px;
      cursor: pointer;
    }
  }

  .toolbar-container .sat-popover-below::after {
    @media screen and (min-width: 480px) {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: transparent transparent white transparent;
      border-width: 20px;
      top: -49px;
      left: calc(100% / 2);
      transform: translateX(-20px);
    }
  }

  .toolbar-container .sat-popover-above::after {
    @media screen and (min-width: 480px) {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: white transparent transparent transparent;
      border-width: 20px;
      bottom: -49px;
      left: calc(100% / 2);
      transform: translateX(-20px);
    }
  }

  .section-list {
    .toolbar-button {
      span.mat-button-wrapper {
        padding: 0px;
        line-height: 24px;
      }
    }
  }

  app-module .mat-expansion-panel-body {
    padding: 0px;
  }

  // :face_vomiting
  entry-form-rich-text {
    &.hint, &.opt {
      .container {
        quill-editor {
          .ql-container {
            .ql-editor {
              &.ql-blank::before {
                font-weight: 400;
                color: $primary-text-muted;
              }
            }
          }
        }
      }
    }
  }

  // begin :vomit
  .mat-checkbox-checked .mat-checkbox-background {
    background: url(/assets/icons/check.svg) no-repeat center center;
    background-size: contain;
  }
  .mat-checkbox-indeterminate .mat-checkbox-background {
    background: url(/assets/icons/check_indeterminate.svg) no-repeat center center;
    background-size: contain;
  }
  // end :vomit

  .mat-slide-toggle:not(.mat-disabled).cdk-keyboard-focused
    .mat-slide-toggle-persistent-ripple {
    opacity: 0.5;
  }

  .mat-option.mat-focus-indicator.mat-active:not(.button-option) {
    border: 1px solid black;
  }

  .mat-chip-list {
    .mat-standard-chip:focus {
      border: 2px solid black;
    }

    .mat-standard-chip:focus::after {
      opacity: 0;
    }
  }

  .mat-focus-indicator.cdk-keyboard-focused {
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      box-sizing: border-box;
      pointer-events: none;
      display: block;
      border: 2px solid black;
      border-radius: 2px;
    }

    &.dark {
      &::before {
        border: 2px solid white;
      }
    }

    &:focus::before {
      content: '';
    }
  }

  .wide-snackbar {
    max-width: 800px;
  }
}
