@use 'sass:map';

/** BREAKPOINTS **/
$breakpoints: (
  xs: 'screen and (max-width: 599px)',
  sm: 'screen and (min-width: 600px) and (max-width: 959px)',
  md: 'screen and (min-width: 960px) and (max-width: 1279px)',
  lg: 'screen and (min-width: 1280px) and (max-width: 1919px)',
  xl: 'screen and (min-width: 1920px) and (max-width: 5000px)',

  lt-xs: 'screen and (max-width: 599px)',
  lt-sm: 'screen and (max-width: 599px)',
  lt-md: 'screen and (max-width: 959px)',
  lt-lg: 'screen and (max-width: 1279px)',
  lt-xl: 'screen and (max-width: 1919px)',

  gt-xs: 'screen and (min-width: 600px)',
  gt-sm: 'screen and (min-width: 960px)',
  gt-md: 'screen and (min-width: 1280px)',
  gt-lg: 'screen and (min-width: 1920px)',
);

@mixin responsive($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    @media #{map.get($breakpoints, $breakpoint)} {
      @content;
    }
  }
}

@mixin makeItResponsive($class) {
  .#{$class} {
    @content;
  }

  @each $breakpoint, $query in $breakpoints {
    @media #{$query} {
      .#{$breakpoint}\:#{$class}.#{$breakpoint}\:#{$class} {
        @content;
      }
    }
  }
}

@mixin makeItNegativeResponsive($class) {
  .#{$class} {
    @content;
  }

  @each $breakpoint, $query in $breakpoints {
    @media not #{$query} {
      .#{$breakpoint}\:#{$class}.#{$breakpoint}\:#{$class} {
        @content;
      }
    }
  }
}

@mixin flex($value) {
  @if ($value == noshrink) {
    flex: 1 0 auto !important;
    box-sizing: border-box !important;
  } @else {
    flex: 1 1 $value !important;
    box-sizing: border-box !important;
  }
}

@mixin flex-min($value) {
  flex: 1 0 $value !important;
  box-sizing: border-box !important;
  --min-dimension: #{$value};
}

@mixin flex-max($value) {
  flex: 0 1 $value !important;
  box-sizing: border-box !important;
  --max-dimension: #{$value};
}

@mixin flex-minmax($value) {
  flex: 0 0 $value !important;
  box-sizing: border-box !important;
  --min-dimension: #{$value};
  --max-dimension: #{$value};
}

@mixin gap($value) {
  gap: $value !important;
}

@mixin gap-x($value) {
  column-gap: $value !important;
}

@mixin gap-y($value) {
  row-gap: $value !important;
}
