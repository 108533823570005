@use 'sass:map';

@use '@angular/material' as mat;
@import '@angular/material/theming';

$pl-orange: (
  50 : #FFEAD6,
  100 : #FFDEBD,
  200 : #FFCC99,
  300 : #FFAE5C,
  400 : #FF972E,
  500 : #FF8000, // Added hue 500
  600 : #E57300,
  700 : #D16900,
  800 : #B85C00,
  900 : #753B00,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$pl-blue: (
  50 : #DAF0F6,
  100 : #77C2EE,
  200 : #1E9AE3,
  300 : #01507F,
  400 : #01507F,
  500 : #01507F, // Added hue 500
  700 : #01507F, // Added hue 500
  800 : #01507F, // Added hue 500
  900 : #01507F, // Added hue 500
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF,
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
  )
);

$pl-green: (
  50 : #C0F2E4,
  100 : #61D3B3,
  200 : #32B48F,
  300 : #025940,
  400 : #025940,
  500 : #025940, // Added hue 500
  700 : #025940, // Added hue 500
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF,
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
  )
);

$pl-black: (
  0 : #FFFFFF,
  50 : #FAFAFA,
  100 : #ECEFF1, // app background
  200 : #D8D8D8, // applied to borders, etc.
  300 : #979797,
  400 : #4F4F4F,
  500 : #333333, // Added hue 500
  600 : #222222,
  700 : #111111,
  800 : #000000,
  900 : #000000,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF,
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
  )
);

$pl-red: (
  0: #DB2C00,
  100: #DB2C00,
  200: #DB2C00,
  300: #DB2C00,
  400: #DB2C00,
  500: #DB2C00, // added hue 500
  700: #DB2C00, // added hue 700
  800: #DB2C00, // added hue 700
  900: #DB2C00, // added hue 700
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF,
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
  )
);

// Define palettes for app themes
$pl-app-orange: mat.define-palette($pl-orange);
$pl-app-blue: mat.define-palette($pl-blue);
$pl-app-green: mat.define-palette($pl-green);
$pl-app-black: mat.define-palette($pl-black);
$pl-app-red: mat.define-palette($pl-red);

// App UI (light theme)
$background-light: mat-color($pl-app-black, 0);
$background-darker: mat-color($pl-app-black, 100);

// TODO remove
$background-light-hover: #EEEEEE;
$background-dark: #424242;

$primary-text-color: mat-color($pl-app-black, 500);
$primary-text-muted: mat-color($pl-app-black, 400);

$pl-app-theme: mat.define-light-theme((
  color: (
    primary: $pl-app-black,
    accent: $pl-app-orange,
    warn: $pl-app-red
  ),
));

$background: (
  background: $background-light
);
$pl-app-theme: map.deep-merge($pl-app-theme, (
  color: (
    background: $background
  ),
  background: $background
));

$foreground: (
  // base: black,
  text: $primary-text-color,
  secondary-text: $primary-text-muted,
);
$pl-app-theme: map.deep-merge($pl-app-theme, (
  color: (
    foreground: $foreground
  ),
  foreground: $foreground
));

$accent-color: map-get($pl-app-theme, accent);

$border-standard-light: mat-color($pl-app-black, 200);
$standard-radius: 8px;

// Button Styles
$border-orange-button: mat-color($pl-app-orange, 900);
$active-orange-button: mat-color($pl-app-orange, 600);
$disabled-orange-button: mat-color($pl-app-orange, 50);
$background-orange-button: mat-color($pl-app-orange, 50);

$border-blue-button: #00639E;
$background-blue-button: #DAF0F6;

$border-green-button: #057656;
$background-green-button: #CDF4E9;

$border-red-button: #E53935;
$wrong-answer-hint: mat-color($pl-app-red, 200);

$background-red-button: #FFCDD2;

$brown-color-text-button: #7B3E00;
$orange-color-background-button: #FFB870;
$light-gray-background-button: #FAFAFA;

// PocketLab Colours
$pl-light-grey: mat-color($pl-app-black, 100);
$pl-medium-grey: #B4B4B4;
$pl-dark-grey: mat-color($pl-app-black, 400);
$pl-orange: #FF8800;
$pl-blue: #0A6CD0; // was 1E9AE3;
$accent-color-standard: #FF8800;

// Explicit Status Color Values
$red-color-standard: #DB2C00;
$blue-color-standard: #144F9F;
$green-color-standard: #145432;
$green-color-status-fill: #ADF0DD;
$green-color-status-icon: #32B48F;

// Typography
$primary-font-family: 'Roboto, Helvetica';
$secondary-font-family: 'Montserrat', sans-serif;
$secondary-text-color: mat-color($pl-app-black, 400);
$tertiary-text-color: #636363;

$menu-label-color: #1D1B20;

// Mixins for uniformly setting colors/opacity
@mixin set-background-color($color, $opacity: 1.0) {
  background: rgba($color, $opacity);
}

@mixin set-text-color($color, $opacity: 1.0) {
  color: rgba($color, $opacity);
}

// Shared Data Table
$data-table-border: 1px solid #CECECE;
$data-table-border-active: 1px solid #000;
$data-table-button-hover: #ECEFF1;
$data-table-button-active: #CECECE;

@mixin icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

// Feedback Status Colors
$wO: #6E0B00;
$aT: #E2752B;
$yGI: #00639E;
$aB: #145432;
